import React from 'react';
import { FilterProps } from '../types/interfaces';


const BooleanFilterCheckbox: React.FC<FilterProps> = ({ labelText, onFilterChange }) => {
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onFilterChange(event.target.checked); // Pass the checkbox value to the parent
    };

    return (
        <div className="mb-4">
            <label>
                <input type="checkbox" onChange={handleCheckboxChange} />
                {labelText}
            </label>
        </div>
    );
};

export default BooleanFilterCheckbox;
