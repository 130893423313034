import React, { useState, useRef } from 'react';
import './GenericTable.css'
import { GenericTableProps, SortConfig } from '../../types/interfaces';



const GenericTable = <T extends Record<string, any>, DetailType>({ data, getId, columns, ExpandedRowComponent, getDetailItem }: GenericTableProps<T, DetailType>): JSX.Element => {
  const [columnWidths, setColumnWidths] = useState<number[]>(columns.map(() => 400)); // Default width
  const [sortedData, setSortedData] = useState<T[]>(data); // To hold sorted data
  const [sortConfig, setSortConfig] = useState<SortConfig<T>>({ direction: 0 }); // Sorting config
  const [expandedRow, setExpandedRow] = useState<any | null>(null)

  // To handle resizing logic, we need to track the current column being resized
  const resizableRef = useRef<number | null>(null); // Stores the index of the column being resized
  const initialWidth = useRef<number | null>(null); // Stores the index of the column being resized
  const initialMousePosition = useRef<number | null>(null)

  const handleRowClick = (entryId: any) => {
    console.log(entryId)
    setExpandedRow(expandedRow === entryId ? null : entryId)
  }

  const handleResizeStart = (index: number, event: React.MouseEvent) => {
    resizableRef.current = index;
    initialWidth.current = columnWidths[index]
    initialMousePosition.current = event.clientX // Set the index of the column being resized
    event.stopPropagation();
  };

  const handleResizeMove = (event: MouseEvent) => {
    if (resizableRef.current !== null && initialMousePosition.current !== null && initialWidth.current) {
      const newWidths = [...columnWidths];
      const index = resizableRef.current;

      const deltaX = event.clientX - initialMousePosition.current; // Calculate movement in X direction
      newWidths[index] = Math.max(50, initialWidth.current + deltaX); // Ensure minimum width of 50px

      setColumnWidths(newWidths);
    }
  };

  const handleResizeStop = () => {
    resizableRef.current = null; // Stop resizing when the mouse is released
    initialMousePosition.current = null
  }

  const handleHeaderMouseUp = (sortConfig: SortConfig<T>) => {
    if (resizableRef.current === null) {
      handleSort(sortConfig)
    }
    handleResizeStop()
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', handleResizeMove)
    document.addEventListener('mouseup', handleResizeStop)
    return () => {
      document.removeEventListener('mousemove', handleResizeMove)
      document.addEventListener('mouseup', handleResizeStop)
    };
  });

  React.useEffect(() => {
    if (data.length !== sortedData.length) {
      setSortedData(data)
      if (sortConfig.direction > 0) {
        handleSort(sortConfig)
      }
    }
  })

  // Sorting function
  const handleSort = (sortConfig: SortConfig<T>) => {
    var sorted = sortedData
    sortConfig.direction %= 3

    if (sortConfig.direction > 0 && sortConfig.column !== undefined) {
      sorted = [...data].sort((a, b) => {
        const aValue = columns[sortConfig.column!].getValue(a)
        const bValue = columns[sortConfig.column!].getValue(b)

        if (aValue <= bValue) return sortConfig.direction === 1 ? -1 : 1;
        else return sortConfig.direction === 1 ? 1 : -1;
      })
    };

    setSortedData(sorted);
    setSortConfig(sortConfig);
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <table className="border-collapse">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                style={{ width: `${columnWidths[index]}px`, position: 'relative' }}
                className="border px-4 py-2 bg-gray-800 text-white"
                onMouseUp={(e) => handleHeaderMouseUp({ column: index, direction: sortConfig?.direction + 1 })} // Add click handler for sorting
              >{
                /* Indicate sort direction */}
                {sortConfig.column === index && sortConfig.direction > 0 && (
                  <span>{sortConfig.direction === 1 ? '🔼 ' : '🔽 '}</span>
                )}
                {column.label}
                {/* Resizable handle */}
                <span
                  onMouseDown={(e) => handleResizeStart(index, e)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    width: '10px',
                    cursor: 'col-resize',
                    zIndex: 1,
                    backgroundColor: 'white',
                    opacity: '10%',
                  }}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map(item => (
            <React.Fragment key={getId(item)}>
              <tr key={getId(item)} onClick={() => handleRowClick(getId(item))} className="bg-gray-700 hover:bg-gray-600 text-white">
                {columns.map((column, colIndex) => (
                  <td
                    style={{ width: `${columnWidths[colIndex]}px` }}
                    className="border px-4 py-2"
                  >
                    {String(column.getValue(item))}
                  </td>
                ))}
              </tr>
              {ExpandedRowComponent && getDetailItem && expandedRow === getId(item) && (
                <tr className='expanded-row' key={`${getId(item)}-expanded`}>
                  <td colSpan={columns.length}>
                    {ExpandedRowComponent ? <ExpandedRowComponent item={getDetailItem(item)} /> : null}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GenericTable;
