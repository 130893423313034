import { Link } from "react-router-dom"
import "./Navbar.css"
import { triggerImport, getImportProgress } from "./api/PlayerDataApi";
import { useState } from "react";
import { ImportProgress } from "../types/interfaces";

function Navbar() {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState<ImportProgress | undefined>();

    const fetchProgress = async () => {
        return await getImportProgress().catch((error) => {
            if (error.response && error.response.status === 404) {
                return undefined;
            }
            throw error;
        })
    }

    const handleImport = async () => {
        setLoading(true);
        setProgress({ progress: 0, total: 100 });
        try {
            console.log("start loading")
            await triggerImport();
            let currentProgress = progress;
            do {
                console.log(`Import progress: ${currentProgress}%`)
                await new Promise(resolve => setTimeout(resolve, 1000)) // wait for 1 second before checking again

                currentProgress = await fetchProgress();
                setProgress(currentProgress);
            } while (currentProgress)
        } finally {
            console.log("finish loading")
            setLoading(false);
        }
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/">MyApp</Link>
            </div>
            <ul className="navbar-links">
                <li><Link to="/list/faction">Factions</Link></li>
                <li><Link to="/list/reputation">Reputation</Link></li>
                <li><Link to="/list/reputation/best">Highest Reputation</Link></li>
                <li>
                    <button onClick={handleImport} disabled={loading} className="navbar-link-button fixed-width-button">
                        {loading ? `${progress?.progress}/${progress?.total}` : "Import"}
                    </button>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;