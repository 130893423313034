// src/components/UserList.tsx

import React, { useEffect, useState } from 'react';
import './FactionList.css'
import { Column, Faction, ReputationTier } from '../types/interfaces';
import GenericTable from './table/GenericTable';
import ExpandedFactionRow from './table/ExpandedFactionRow';
import { getFactions, getRepTiers } from './api/GameDataApi';

const FactionList: React.FC = () => {
  const [factions, setFactions] = useState<Faction[]>([])
  const [reputationTiers, setReputationTiers] = useState<{ [key: string]: ReputationTier }>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  // Fetch the user list when the component mounts
  useEffect(() => {
    const fetchFactions = async () => {
      try {
        const [factionResponse, tierResponse] = await Promise.all([getFactions(), getRepTiers()])

        setFactions(factionResponse)
        setReputationTiers(tierResponse.reduce((acc: { [key: string]: ReputationTier }, tier: ReputationTier) => {
          acc[tier.uuid] = tier
          return acc
        }, {}))
      } catch (err) {
        setError("There was an error fetching the factions")
      } finally {
        setLoading(false)
      }
    };
    fetchFactions();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Define the columns to display for users
  const factionColumns: Column<Faction>[] = [
    { getValue: item => item.name, label: 'Name' },
    { getValue: item => item.description, label: 'Description' },
  ];

  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">Faction List</h2>
      <GenericTable data={factions} getId={item => item.uuid} columns={factionColumns} ExpandedRowComponent={ExpandedFactionRow}
        getDetailItem={item => item.reputationTier ? reputationTiers[item.reputationTier!] : undefined} />
    </div>
  );
};

export default FactionList;
