// src/components/ExpandedFactionRow.tsx
import React from 'react';
import { Faction, ReputationTier } from '../../types/interfaces';

interface ExpandedFactionRowProps {
    item?: ReputationTier;
}

const ExpandedFactionRow: React.FC<ExpandedFactionRowProps> = ({ item }) => {
    return (
        <div>
            <p><strong>Progress Levels:</strong></p>
            <ul>
                {item && item.levels.map(level =>
                    <li><strong>{level.minValue} - {level.maxValue}:</strong> {level.name}</li>
                )}
            </ul>
        </div>
    );
};

export default ExpandedFactionRow;
