// src/Login.tsx
import React from 'react';


const Login: React.FC = () => {
  const handleBattlenetLogin = async () => {
    try {
      console.log('request oauth2')

      window.open(`${process.env.REACT_APP_BACKEND_URL}/oauth2/authorization/battle-net`)

    } catch (error) {
      console.error('Error initiating Battle-net login:', error)
    }
  };

  return (
    <div>
      <h1>Welcome to our Social Login App!</h1>
      <button onClick={handleBattlenetLogin}>Login with Battle.net</button>
    </div>
  );
};

export default Login;
