// src/components/UserList.tsx

import axios from 'axios';
import { ImportProgress, ReputationProgress } from '../../types/interfaces';

const MAIN_PATH = `${process.env.REACT_APP_BACKEND_URL}/api/player/reputation`

function getCsrfToken(): string | null {
    const name = 'XSRF-TOKEN='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return null
}

function getResponse<T>(subPath: string): Promise<T> {
    return axios.get<T>(`${MAIN_PATH}${subPath}`, { withCredentials: true })
        .catch(err => {
            if (err.response?.status !== 401) {
                return err
            }
            window.location.href = `/login`
        })
        .then(res => res?.data)
}

function post<T>(subPath: string, body?: any): Promise<T> {
    const csrfToken = getCsrfToken()
    return axios.post<T>(`${MAIN_PATH}${subPath}`, body, {
        withCredentials: true,
        headers: {
            'X-XSRF-TOKEN': csrfToken || ''
        }
    })
        .catch(err => {
            if (err.response?.status !== 401) {
                return err
            }
            window.location.href = `/login`
        })
        .then(res => res?.data)
}

export function getHighestReputations(): Promise<ReputationProgress[] | undefined> {
    return getResponse<ReputationProgress[]>('/list/highest')
}

export function getReputations(): Promise<ReputationProgress[] | undefined> {
    return getResponse<ReputationProgress[]>('/list')
}

export function triggerImport(): Promise<void> {
    return post<void>('/import')
}

export function getImportProgress(): Promise<ImportProgress | undefined> {
    return getResponse<ImportProgress>('/import')
}
