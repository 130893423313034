// src/components/UserList.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FactionList.css'
import { ReputationProgress, Column, Progress } from '../types/interfaces';
import GenericTable from './table/GenericTable';
import { getReputations } from './api/PlayerDataApi';


const columns: Column<Progress>[] = [
  { getValue: progress => progress.charName, label: 'Character' },
  { getValue: progress => progress.realm, label: 'Realm' },
  { getValue: progress => progress.overallProgress, label: 'Progress' },
  { getValue: progress => progress.overallLeft, label: 'Left' },
]

const FactionProgressList: React.FC = () => {
  const [factionsWithProgresses, setFactionsWithProgresses] = useState<ReputationProgress[]>([]);  // State to store the list of users
  const [loading, setLoading] = useState<boolean>(true);  // Loading state
  const [error, setError] = useState<string | null>(null);  // Error state

  // Fetch the user list when the component mounts
  useEffect(() => {
    const fetchFactions = async () => {
      try {
        const response = await getReputations()
        if (response) {
          setFactionsWithProgresses(response);  // Set the fetched data to the state
        }
      } catch (err) {
        setError("There was an error fetching the factions");
      } finally {
        setLoading(false);  // Set loading to false after data is fetched
      }
    };
    fetchFactions();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">Reputation List</h2>
      {factionsWithProgresses.map(factionWithProgresses =>
        <div>{factionWithProgresses.faction.name}
          <GenericTable data={factionWithProgresses.progresses} getId={item => item.uuid} columns={columns} />
        </div>
      )}
    </div>
  );
};

export default FactionProgressList;
