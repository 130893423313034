import ReactDOM from "react-dom"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import FactionList from "./components/FactionList";
import App from "./App";
import FactionProgressList from "./components/FactionProgressList";
import BestValueFactionList from "./components/BestValueFactionList";
import Login from "./Login";
import Navbar from "./components/Navbar";

export default function Index() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<App />} />
        <Route path="/login" element={<Login />} />
        <Route path="/list/faction" element={<FactionList />} />
        <Route path="/list/reputation" element={<FactionProgressList />} />
        <Route path="/list/reputation/best" element={<BestValueFactionList />} />
      </Routes>
    </BrowserRouter>
  );
}
ReactDOM.render(<Index />, document.getElementById("root"));
