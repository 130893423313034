// src/components/UserList.tsx

import axios from 'axios';
import { Faction, ReputationTier } from '../../types/interfaces';

const MAIN_PATH = `${process.env.REACT_APP_BACKEND_URL}/api/game/reputation`

function getResponse<T>(subPath: string): Promise<T> {
    return axios.get<T>(`${MAIN_PATH}${subPath}`).then(res => res.data)
}

export function getFactions(): Promise<Faction[]> {
    return getResponse<Faction[]>('/factions')
}

export function getRepTiers(): Promise<ReputationTier[]> {
    return getResponse<ReputationTier[]>('/factions/tiers')
}
